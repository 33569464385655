.mpcontainer {
  text-align: center;
  /* position: absolute; */
  /* left: 0;
  right: 0; */
  /* transform: translateY(1%); */
  font-family: 'Red Hat Display', sans-serif;
}

.maincontainer {
  /* bottom: 50%; */
  /* transform: translateY(+50%)!important; */
  background-color: #efeef1;
  font-family: 'Red Hat Display', sans-serif;
}

.mpcontainer strong {
  font-size: 20px;
  line-height: 26px;
}

.mpcontainer p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
  font-family: 'Red Hat Display', sans-serif;
}

.mpcontainer a {
  text-decoration: none;
}

ion-input, ion-textarea{
  border-bottom: solid 1px;
}
.displayNone { display: none; }
.statusLabelVisible {padding-bottom: 20px; display: block; visibility: visible; color: #3880ff;}
.topLabel {  text-align: center;}
.ImInStatus {padding-top: 20px;}
.countdownLabelDiv {padding-bottom: 20px; white-space: pre-line;}
.countdownLabel {font-size: x-large; color: black;}
.ImOKButton {width: 60%; height: 200px; margin-bottom: 40px;}
.ion-page {background-color: #efeef1;}
ion-content{--ion-background-color: #efeef1; --background: #efeef1 !important;}
.whitebg {background-color: white;}
ion-toolbar {--ion-background-color: white; color: black;}
ion-label {color: black;}
.native-input .sc-ion-input-md {color: black;}
ion-textarea, ion-input {
  background: white!important;
  border: 2px solid white;
  margin: 4px;
  width: 97%!important;
}
.locationInput {
  text-align: center;
}
.locationInput > input {
  margin-left: 10px;
  margin-right: 10px;
}
.geobutton { cursor: pointer;}
.goingin {cursor: pointer; }
.cursorPointer {cursor: pointer; }
.locationLabelDiv {padding-bottom: 2px;}
.locationLabel {font-size: larger; color: black;}
.modalButton {width: 50%; height: 44px;}
.imokmodal {background: white !important; --background: white;}
.modal-wrapper .item-native .item-inner .input-wrapper {background-color: white !important;}
.blackbg {background-color: white !important;}
.smallLabel {font-size: small; text-align: left;}
.IMOKIonCheckbox {--ion-background-color: white; margin-inline-end: 12px!important;}
/* .errorLabel {} */
.IonSelect {background-color: white;}
.leftalign {float: left; padding-left: 22px; padding-right: 29px;}
.leftalign-nofloat {
  padding-left: 22px; 
  /* padding-right: 29px;  */
  text-align: left;
}
.fullCredits {color: #4f4f4f!important; letter-spacing: 3px;} /* b6424a 53508f */
.emptyCredits {color: #333!important; letter-spacing: 3px;}
.white {color: black;}
.warn {color:#ffc409;}
.subscriptionGrid {width: 80%;}
.subscriptionUnChosen {opacity: 100%;}
.subscriptionChosen {opacity: 100%;}
.subscriptionRadio {opacity: 1000%;}
.subscriptionPrice {color: #aaa; font-size: small; text-align: center; width: 100%; transform: translateX(-0px);}
.paddingbottom {padding-bottom: 30px;}
.stripeForm {align-content: center;}
#card-element{width: 90%; position: absolute; transform: translate(3%, 0%);}
/* .googlePlayIcon {padding-top: 19px;} */

.alert-button-group {
  flex-direction: row !important;
}
.sureRate {
  font-weight: bolder!important;
  /* color: #ffc409!important; */
}
.topicon {
background-color: white!important;
--background: white!important;
}
.topiconButton {
padding-left: 4px!important;
padding-right: 4px!important;
}
.topicon-web {
  height: 24px;
}
.button-solid {
  --background: white!important;
  --box-shadow:none!important;
  padding-left: 15px;
  padding-right: 15px;
}
@media only screen and (max-width: 350px) {
  .button-solid, .topiconButton {
    padding-left: 9px!important;
    padding-right: 9px!important;
  }
}
.header-md {
  text-align: center;
}
.profilePhoto {
  width: 100%!important;
}
.eventPhoto {
  width: 100%!important;
  /* aspect-ratio: 10/7; */
}
.profilePhotoWide {
  width: 450px!important;
  height: 450px!important;
  object-fit:cover!important;
  border-radius:50%;
}
.profilePhotoWideBig {
  width: 450px!important;
  height: 450px!important;
  object-fit:cover!important;
  border-radius:50%;
}
.profilePhotoWideBigHover {
  border-radius:0%!important;
  margin-top: -40px;
}
.swipeProfilePictureLeft {
  position: fixed;
  z-index: 9999;
  float: left;
  margin-top: 210px;
  margin-left: -75px;
}
.swipeProfilePictureRight {
  position: fixed;
  z-index: 9999;
  float: right;
  margin-top: 210px;
}
.profileDetails {
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 1.5em;
}
.profileName {
  font-size: xx-large;
}
.profileCity {
  font-size: large;
  color: #999;
}
.profileBio {
  text-align: left;
  color: #777;
}
.editicon {
  font-size: 58px;
}
.editiconfab {
  position: initial;
  float: right;
  transform: translate(-20%,20%); 
  
}
.profileThumb {
  max-height: 100px;
}
.setupLabel {
  text-align: left;
  padding-left: 10px;
  padding-right: 5px;
}
.setupLabel ion-label {
  padding-left: 14px;
}
.emptyProfileThumb {
  background-color: #EAEDF2;
  border: 4px dashed #E0E3E8;
  margin: 10px;
  height: 30%;
}
/* .emptyProfileThumb > .profileThumb {
  visibility: hidden;
} */
.addphotoicon {
  font-size: 48px;
}
.addphotoiconfab {
  position: initial;
  float: right;
  transform: translate(20%,20%); 
}
.profileThumbReorder {
  padding-top: 10px;
}


.pinkButton {
  background-color: #ec0f47;
  --background: #ec0f47!important;
  border-radius: 6px;
}
.greenButton {
  background-color: #4BCA92;
  --background: #4BCA92!important;
  border-radius: 6px;
}

.greyButton {
  background-color: #FFF;
  --background: #FFF!important;
  border: 2px solid #9B9CA4;
  border-radius: 6px;
  color: #9B9CA4;
}
.greyButtonHello {
  padding: 6px;
  background-color: #AAA;
  color: white;
  border: 0;
  width: 100px;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input;
}
ion-select {
  background-color: white;
  width: 97%!important;
  margin: 4px;
}
Ion-Datetime {
  background-color: white;
  width: 97%!important;
  margin: 4px;
}
ion-range {
  --bar-background: grey;
  --bar-background-active: #ec0f47;
  --knob-background: #ec0f47;
}
.pinkButton {
  --background: #ec0f47;
}
.pinkLabel {
  color: #ec0f47;
}
.addPhotoIcon {
  height: 42px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.smallGreyLabel {
  font-size: smaller;
  color: #777;
  display: block;
}
.mediumGreyLabel {
  font-size: smaller;
  color: #777;
  display: block;
}
.fbAlbumThumb {
  width: 64px;
  height: 48px;
  object-fit: fill;
  vertical-align: top;
}
.fbAlbumThumbTD {
  padding-right: 14px;
  width: 64px;
  align-content: center;
}
.fbPhotoThumb {
  width: 33.33%;
  height: 83px;
  margin-top: -4px;
  object-fit: fill;
}
.dummyImg {
  visibility: none;
}
.cropDialog {
  top: 20px!important;
}
.cropButtonDiv {
  width: 100%;
}
.centeredButton {
  width: 98%;
  /* left: 4%; */
  display: block;
  position: relative;
}
.dummyImgDiv {
  visibility: hidden;
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.alignLeft {
  text-align: left;
}
.alignRight {
  text-align: right;
}
.myEvents {
  height: 134px;
  scrollbar-width: none;
  display: inline-table;
  /* overflow-y: hidden;
  overflow-x: scroll; */
}
.myEvent {
  display: inline-flex;
  padding-right: 22px;
  height: 134px;
}
.myEventHello {
  display: inline-grid!important;
  padding-right: 0px!important;
  padding-top: 4px;
  margin-left: -10px;
}
.myEventPhoto {
  height: 110px;
  width: 100px;
  object-fit: fill;
  margin-bottom: -46px;
  display: inline-flex;
  border-radius: 8px;
}
.expiredEventPhoto {
  filter: blur(2px) grayscale(1);
}
.pendingEventPhoto {
  /* filter: opacity(30%); */
  filter: unset;
  /* border: 4px solid #ffc409; */
}
.pendingEventTitle {
  /* border-bottom: 3px solid #ffc409;
  border-right: 4px solid #ffc409;
  border-left: 4px solid #ffc409; */
  opacity: 0.8!important;
  background-color: #ffc409!important;
}
.myEventTltle {
  height: 40px;
  /* left: 9px; */
  /* bottom: -50px; */
  position: relative;
  display: inline-flex;
  width: 100%;
  align-content: flex-end;
  /* align-items: flex-end; */
  padding-left: 3px;
  color: white;
  text-align: left;
  overflow: hidden;
  background-color: rgba(0, 0, 0, .5);
}
.expiredEventTltle {
  color: gray!important;
}
.pinkFabButton {
  /* background-color: #D65E5E; */
  --background: #ec0f47!important;
  border-radius: 6px;
}
.line-above {
  border-top: 1px solid #b6b7b8;
  margin-top: 8px;
  padding-top: 8px;
}
.line-height-big {
  line-height: 1.3em;
}
.eventAttendingButton {
  position: relative;
  float: right;
  right: 0px;
  /* font-size: 24px; */
  width: 60px;
  margin-right: 15px;
  margin-top: 15px;
}
.eventAttendingButtonGoing {
  width: 80px;
}
.stretchDiv {
  width: 80%;
}
.foundEvent {
  /* background-color: #ddd; */
  background-color: white;
  padding-top: 9px;
  /* border-top: 1px solid #b6b7b8;
  border-bottom: 1px solid #b6b7b8; */
  margin-bottom: 12px;
  height: 90%;
  overflow: scroll;
  border-radius: 17px;
  margin-left: 4px;
  margin-right: 4px;
}
.foundEventPop {
  /* background-color: #ddd; */
  background-color: white;
  padding-top: 36px;
  /* border-top: 1px solid #b6b7b8;
  border-bottom: 1px solid #b6b7b8; */
  margin-bottom: 12px;
  height: 90%;
  overflow: scroll;
}
.foundEventPopWide {
  width: 102.7%;
  padding-left: 8px;
}
.foundEventIos {
  padding-top: 0px;
}
.foundEventWide {
  background-color: white;
  padding-top: 36px;
  margin-bottom: 12px;
  height: 90%;
  width: 80vw;
  max-width: 900px;
  border-radius: 8px;
}
.organizerAddedEvent {
  padding-bottom: 3px;
  font-size: 11pt;
  /* margin-left: -20px; */
  margin-right: 20px;
}
.organizerAddedEventNegativeMarginRight {
  margin-left: -20px;
}
.organizerAddedEventLong {
  white-space: break-spaces!important;
}
.eventPopupToolbar {
  height: 44px;
  top: -10px!important;
  position: fixed;
}
.eventPopupToolbarLong {
  height: unset!important;
  top: unset!important;
  overflow: unset!important;
  white-space: unset!important;
}
.eventPopupToolbarIos{
  /* top: 90px!important; */
  /* left: -70px;
  width: 115%; */
  padding-top: unset!important;
}
.toolbar-container {
  overflow: hidden;
}
.eventDesc {
  padding-top: 14px;
  padding-right: 20px;
}
.eventDate {
  color: #ec0f47;
  font-size: small;
  padding-top: 4px;
}
.createEventInfoPopup {
  --width: 190%;
  top: -5%!important;
  --max-height: 75%!important;
  --max-width: 95%;
}
.createEventInfoPopupWide {
  --width: 100%!important;
  --max-width: 560px;
}
.createEventInfoPopupInner {
  overflow: hidden!important;
}
.genericPopover {
  --width: 190%;
  top: -45%!important;
  /* top: 30% !important; */
  /* left: 50% !important; */
  /* transform: translate(-100%, -110%) !important; */
  --max-height: 55%;
  --max-width: 95%;
}
.genericPopoverWide {
  --width: 100%!important;
  --max-width: 560px;
}
IonBackdrop {
  display: none;
}
.paddingSides {
  padding-left: 16px;
  padding-right: 16px;
}

.smallerFont {
  font-size: smaller;
}

.genericPopoverInner {
  /* background-color: #ddd; */
  background-color: white;
  padding-top: 36px;
  /* border-top: 1px solid #b6b7b8;
  border-bottom: 1px solid #b6b7b8; */
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 12px;
  height: 90%;
  overflow: scroll;
}
.genericPopoverInnerIos {
  padding-top: 0px;
}
ion-backdrop {
  width: 200%;
  height: 250%;
}
.aboutEvent {
  /* max-height: 200px; */
  overflow-y: scroll;
}
.cantGoButton {
  width: 128px!important;
}
.cursorHand {
  cursor: pointer;
}
.myheader {
  position: fixed;
  /* height: 100px; */
  display: contents; 
  /* background-color: #3880ff!important; */
  top: 0px;
}
.myheaderChat {
  position: fixed;
  top: 0px;
}
.myheaderWide {
  width: 610px;
}
.mycontent {
  top: 0px;
  position: relative;
}
.mycontentChat {
  top: 70px;
  position: relative;
}
.iosPaddingTop {
  padding-top: 30px; 
}
.myfooter {
  position: fixed;
  bottom: 0px;
  background-image: url("../theme/footerbg.svg");
  background-repeat: repeat-x;
}
.myFooterIos {
  bottom: 20px;
}
.myfooterWide {
  width: 610px!important;
  background-image: none;
}
.rewindButton {
  margin-left: 3%;
  margin-right: 7%;
  margin-bottom: 18px;
}
.rewindButtonWeb {
  margin-left: 3%;
}
.nopeButton {
  margin-right: 15%;
}
.nopeButtonWeb {
  margin-right: 0%;
}
.likeButton {
  margin-right: 20%;
}
.likeButtonWeb {
  margin-right: 10%;
}
.footer-md::before { background-image: none; } 
.swipingFor {
  padding: 8px;
  display: block;
  position: fixed;
  top: 76px;
  /* left: 0px; */
  right: 10px;
  /* background-color: rgba(255, 255, 255, .3); */
  z-index: 9999;
  width: 100%;
  text-align: right;
}
.swipingForIos {
  top: unset;
}
.swipingForWide {
  width: 610px!important;
  /* left:unset!important; */
  /* transform: translate(10%, 0%) !important; */
  right: unset;
  padding-right: 0px;
  padding-top: 20px;
  --width: 610px!important;
  --max-width: 560px;
  /* background-color: #3880ff; */
}
.centered {
  text-align: center;
}
.chatProfilePopover {
  --width: 160%;
  /* top: -45%!important; */
  /* top: 50% !important;
  left: 50% !important; */
  /* transform: translate(-100%, -110%) !important; */
  /* --max-height: 55%; */
  --max-width: 95%;
}
.chatProfilePopoverWide {
  --width: 70%!important;
  /* --max-height: 170%!important; */
  --max-width: 450px;
}
.matchedPopover {
  --width: 140%;
  /* top: 50% !important;
  left: 50% !important;
  transform: translate(-100%, -110%) !important; */
  --max-height: 95%;
  --max-width: 95%;
}
.matchedPopoverWide {
  --width: 100%;
  --max-width: 80%;
}
.matchedPopoverDiv {
  align-items: center;
  text-align: center;
}
.matchedPicture {
  width: 100px;
  height: 100px;
  object-fit:cover;
  border-radius:50%;
}
.capitalize {
  text-transform: capitalize;
}
.matchedActionButtons {
  width: 210px;
  margin-bottom: 12px;
  border-radius: 21px!important;
}
.setupcontainer {
 top: 0px!important; 
}
.boldLabel {
  font-weight: bold;
}
.matchesList {
  height: 134px;
  scrollbar-width: none;
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  padding-top: 20px;
  padding-left: 8px;
}

.chatsNewMatch {
  display: inline-flex;
  padding-right: 22px;
  height: 134px;
}
.chatsNewMatchPhoto {
  /* margin-bottom: -46px; */
  /* display: inline-flex; */
  width: 80px;
  height: 80px;
  object-fit:cover;
  border-radius:50%;
}
.chatsWTGWTeamIcon {
  object-fit: contain;
}
.chatsNewMatchTitle {
  /* height: 40px; */
  /* left: 9px; */
  /* bottom: -50px; */
  /* display: inline-flex; */
  display: block;
  width: 80px;
  /* align-content: flex-end; */
  /* align-items: flex-end; */
  padding-left: 3px;
  color: black;
  text-align: center;
  overflow: hidden;
  font-weight: bold;
}
.chatProfileTopPhoto {
  width: 40px;
  height: 40px;
  object-fit:cover;
  border-radius:50%;
  /* margin-top: -8px; */
  margin-right: 10px;
}
.topChat {
  /* display: inline-flex;
  padding-top: 15px; */
  display: flex;
  align-items: center;
}
.chatFooter {
  position: fixed;
  bottom: 0px;
  width: 100%;
  max-width: 670px;
}
.chatFooterIos {
  bottom: 30px;
}
.chatSendButton {
  padding-left: 10px;
}
.toMsg {
  text-align: right;
  margin-right: 10px;
}
.fromMsg {
  text-align: left;
  margin-left: 10px;
}
.fromMsg, .toMsg {
  display: block;
  padding: 10px;
  margin-top: 4px;
}
.chatBGFrom {
  background-color: #ccc;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 10px;
  display: block;
  text-align: start;
  unicode-bidi: plaintext;
}
.chatBGFrom:after {
  border-radius: 15% 0 0 0 / 25%;
}
.toMsg > .inlineDiv > .chatBGFrom {
  background-color: #3CA4FF;
}
.chatTimeStamp {
  font-size: small;
  color: #555;
}
.chatContainer {
  margin-bottom: 120px;
}
.inlineDiv {
  display: inline-block;
}
.messagesList {
  display: inline;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 20px;
  padding-left: 8px;
  height: 90%;
}

.chatsConversation {
  display: block;
  padding-right: 22px;
  height: 98px;
}
.chatsConversationPhoto {
  width: 80px;
  height: 80px;
  object-fit:cover;
  border-radius:50%;
  display: inline;
}
.chatsConversationTitle {
  /* height: 40px; */
  /* left: 9px; */
  /* bottom: -50px; */
  /* display: inline-flex; */
  display: block;
  min-width: 200px;
  /* align-content: flex-end; */
  /* align-items: flex-end; */
  padding-left: 3px;
  color: black;
  text-align: left;
  overflow: hidden;
  /* font-weight: bold; */
  padding-bottom: 30px;
  position: relative;
  left: 100px;
  top: -64px;
  width: 80%;
  padding-right: 20px;
}
.chatscontainer {
  height: 85%;
}
.chatMenuHeader {
  text-align: left;
  display: block ruby;
  font-size: initial;
  font-weight: bold;
}
.clearEventSearchField {
  padding-right: 8px;
}
.eventImgDiv {
  position: relative;
}
.eventBadges {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 9;
  text-align: right;
}
.verticalMiddle {
  vertical-align: sub;
}
.verticalMiddleHello {
  position: absolute;
  transform: translateY(23%);
  padding-left: 14px;
  margin-left: 0px;
}
.helloEventDiv {
  left: 50%;
  transform: translateX(-100%);
  position: absolute;
}
.centeredHello {
  display: block;
  height: 240px;
}
.sideCol {
  min-width: 1px;
}
.mainCol {
  /* max-width: 300px; */
  display: block ruby;
  text-align: center;
}
.mainDiv {
  max-width: 620px;
  width: 100%;
  display: inline-block;
}
.mainDivWide {
  max-width: 900px;
  width: 100%;
  display: inline-block;
}
.logoBig {
  padding: 0px 20px;
}
/* .popover-wrapper  {
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
} */
.fileUploadInput {
  visibility: hidden;
  width: 1px;
}
.emptyScreenLabel {
  font-size: larger;
}
.goingIconInChat {
  vertical-align: middle;
}
.findEventInput {
  padding-left: 10px!important;
}
.findEventInputDiv {
  display: flex;
}
.findEventClearButtonDiv {
  padding-left: 8px;
  display: inherit;
}
.chatTextarea {
  /* min-height: 43px;
  height: unset!important; */
  width: 100%;
  padding: 4px;
  height: 43px;
}
.warningPopupPadding {
  padding: 12px;
  border: 3px solid #222;
}
.iconlessBadge {
  height: 24px;
  padding-top: 5px;
}
.textblock {
  text-align: left;
  padding-left: 24px;
  padding-right: 26px;
}
.red{
  color: red;
}
.menuIcon {
  height: 21px;
}
.disabledText {
  color: lightgray;
}
.rtl {
  direction: rtl;
  text-align: right;
}
.paddingBottom{
padding-bottom: 4px;
}
.footerBoldLabel {
  /* font-size: large; */
  margin-top: 9px;
  display: -webkit-inline-box;
  padding-right: 12px;
}
.footerTopCol {
  border-bottom: 1px solid #aaa;
  padding-bottom: 12px;
}
.footerGrid {
  background-color: #10234f;
  padding-left: 20%;
  padding-right: 20%;
}
.footerGridMobile {
  background-color: #10234f;
}
.footerSecondRow {
  padding-top: 22px;
}
.footerContainer {
  position: relative;
}
.mainContainerForFooter {
  min-height: 80vh;
}
.footerLanguageSelect {
  background-color: #000;
  border-radius: 4px;
  color: #aaa;
  border: 1px solid #aaa;
  width: 320px!important;
}
.apppleStoreIcon, .googlePlayIcon {
  width: 160px;
  height: 44px;
  padding-right: 6px;
  padding-left: 6px;
}
.appDownloadIcons {
  display: block;
  white-space: nowrap;
}
.footerWhiteLink {
  color: #aaa;
}
pre {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
.searchBar {
	top: 0px;
	position: sticky;
	z-index: 999;
	background: white;
}
.eventLink {
  border: 0px!important;
  width: 100%;
  outline: none;
  color: dodgerblue;
}
.WebFooter {
  /* background-color: #10234f!important; */
  color: #aaa;
  display: block;
  position: absolute;
  /* bottom: 0; */
  width: 100%;

}


.scroll-slow {
 height: 28px;	
 overflow: hidden;
 position: relative;
 /* background: yellow;
 color: orange;
 border: 1px solid orange; */
}
.scroll-slow p {
 position: absolute;
 /* width: 100%; */
 height: 100%;
 margin: 0;
 /* line-height: 50px; */
 text-align: center;
 /* Starting position */
 -moz-transform:translateX(-50%);
 -webkit-transform:translateX(-50%);	
 transform:translateX(-50%);
 /* Apply animation to this element */	
 -moz-animation: scroll-slow 25s linear infinite;
 -webkit-animation: scroll-slow 25s linear infinite;
 animation: scroll-slow 25s linear infinite;
}
/* Move it (define the animation) */
@-moz-keyframes scroll-slow {
 0%   { -moz-transform: translateX(100%); }
 100% { -moz-transform: translateX(-100%); }
}
@-webkit-keyframes scroll-slow {
 0%   { -webkit-transform: translateX(100%); }
 100% { -webkit-transform: translateX(-100%); }
}
@keyframes scroll-slow {
 0%   { 
 -moz-transform: translateX(100%); /* Browser bug fix */
 -webkit-transform: translateX(100%); /* Browser bug fix */
 transform: translateX(100%); 		
 }
 100% { 
 -moz-transform: translateX(-100%); /* Browser bug fix */
 -webkit-transform: translateX(-100%); /* Browser bug fix */
 transform: translateX(-100%); 
 }
}
.preLine {
  white-space: pre-line;
}
.reportLink {
  float: right;
  padding-right: 40px;
  font-size: 10pt;
  color: red;
  cursor: pointer;
  margin-top: 10px;
}
.shareContainer {
  float: left;
  padding-left: 20px;
  font-size: 10pt;
  color: #3880ff;
  cursor: pointer;
}
ion-modal {
  --width: 290px;
  --height: 412px;
  --border-radius: 8px;
}